import { Period } from '../../store/leagues';

export const lastDayOfMonth = (date: string) => {
	return new Date(
		new Date(date).getFullYear(),
		new Date(date).getMonth() + 1,
		0,
		23,
		59,
		59,
		999
	);
};

export const dateTimezoneOffset = (date: string | number | Date) =>
	new Date(
		new Date(date).getTime() + new Date(date).getTimezoneOffset() * 60 * 1000
	);

export const formatedDate = (date?: string | number) => {
	const dt = date ? dateTimezoneOffset(date) : undefined;

	return dt
		? `${String(dt.getDate()).padStart(2, '0').slice(-2)}.${String(
				dt.getMonth() + 1
			)
				.padStart(2, '0')
				.slice(-2)}.${String(dt.getFullYear())}`
		: '';
};

export const calculateAge = (
	birth?: string | number | Date,
	actual?: string | number | Date
) => {
	if (!birth || !actual) return undefined;

	return (
		new Date(
			new Date(actual).getTime() - dateTimezoneOffset(birth).getTime()
		).getFullYear() - 1970
	);
};

export const formatPeriod = (period?: Period) => {
	switch (period) {
		case 'year':
			return {
				year: 'numeric',
			} as Intl.DateTimeFormatOptions;
		case 'month':
			return {
				year: 'numeric',
				month: 'long',
			} as Intl.DateTimeFormatOptions;
		default:
			return undefined;
	}
};

export const week = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

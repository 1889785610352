import React, { FormEvent, useState } from 'react';

import { Link } from 'react-router-dom';
import {
	Button,
	CheckboxProps,
	Form,
	Grid,
	Header,
	Image,
	InputOnChangeData,
	Message,
	Segment,
} from 'semantic-ui-react';

import VerifyCode from './validation';
import { ScreenSize } from '../../hooks';
import { useTitle } from '../../hooks/useTitle';
import {
	authConfirmForgotPassword,
	authDismissError,
	authForgotPassword,
	authLogin,
} from '../../store/auth';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import checkValidity from '../../util/valida/check';
import ErrorBoundary from '../errors/boundary';
// import GoogleButton from './google';

interface Props {
	screenSize?: ScreenSize;
}

const LoginForm: React.FC<Props> = ({ screenSize }) => {
	useTitle('Ponto do Esporte - Login');

	const dispatch = useAppDispatch();

	const { loading, forgot, error } = useAppSelector((state) => ({
		loading: state.user.loading,
		forgot: state.user.forgot,
		error: state.user.error,
	}));

	const [loginData, setLoginData] = useState({
		username: '',
		password: '',
		verification: '',
		keepLogged: false,
	});

	const [errors, setErrors] = useState<{ [key: string]: boolean }>({
		username: true,
		password: true,
	});
	const [validate, setValidate] = useState<boolean>(false);

	const changeLoginData = (
		event: React.ChangeEvent<HTMLInputElement>,
		data: InputOnChangeData
	) => {
		setLoginData((state) => ({
			...state,
			[data.field]: data.value,
		}));
		setErrors((state) => ({
			...state,
			[data.field]: checkValidity(
				data.field === 'username' ? 'mail' : data.field,
				data.value
			),
		}));
	};

	const changeKeepLogged = (
		event: FormEvent<HTMLInputElement>,
		data: CheckboxProps
	) => {
		setLoginData((state) => ({
			...state,
			[data.field]: data.checked,
		}));
	};

	const handleForgotPassword = () => {
		if (!errors['username']) {
			dispatch(authForgotPassword(loginData.username));
			setErrors((state) => ({
				...state,
				verification: true,
			}));
		}
	};

	const handleDismissError = () => {
		dispatch(authDismissError());
	};

	// const handleGoogleLogin = (googleToken: string) => {
	// 	dispatch(
	// 		authFederatedLogin({
	// 			GoogleToken: googleToken,
	// 		})
	// 	);
	// };

	const handleSubmit = () => {
		setValidate(true);

		if (Object.values(errors).every((value) => !value)) {
			if (!forgot) {
				dispatch(
					authLogin({
						Username: loginData.username,
						Password: loginData.password,
						KeepMeLogged: loginData.keepLogged,
					})
				);
			} else {
				dispatch(
					authConfirmForgotPassword({
						Username: loginData.username,
						Password: loginData.password,
						ConfirmationCode: loginData.verification,
					})
				);
			}
		}
	};
	// TODO: Avaliar quando o Cognito pede para trocar a senha

	return (
		<Segment
			basic
			padded="very"
			style={{
				alignItems: 'center',
				display: 'flex',
				justifyContent: 'center',
				minHeight: `calc(100vh - ${screenSize === 'mobile' ? '15vh' : '13vh'})`,
			}}
		>
			<ErrorBoundary>
				<Grid
					id="login"
					textAlign="center"
					verticalAlign="middle"
					style={{
						...(screenSize !== 'mobile'
							? { maxWidth: '450px', width: '100%' }
							: { width: '100%' }),
					}}
				>
					<Grid.Column>
						<Header as="h2" color="black" textAlign="center">
							<Image src="\img\pe.png" /> Entre na sua conta
						</Header>
						<Form size="large" loading={loading}>
							<Segment stacked>
								<Form.Input
									field="username"
									value={loginData.username}
									error={validate && errors['username'] && 'E-mail inválido'}
									fluid
									icon="mail"
									iconPosition="left"
									placeholder="E-mail"
									required
									onChange={changeLoginData}
								/>
								{forgot && (
									<Form.Input
										field="verification"
										value={loginData.verification}
										error={
											validate && errors['verification']
												? 'Código inválido'
												: undefined
										}
										fluid
										icon="key"
										iconPosition="left"
										placeholder="Código de verificação"
										required
										onChange={changeLoginData}
									/>
								)}
								<Form.Input
									field="password"
									value={loginData.password}
									error={validate && errors['password'] && 'Senha inválida'}
									fluid
									icon="lock"
									iconPosition="left"
									placeholder={forgot ? 'Nova senha' : 'Senha'}
									type="password"
									required
									onChange={changeLoginData}
								/>
								<Form.Checkbox
									field="keepLogged"
									checked={loginData.keepLogged}
									error={validate && errors['keepLogged']}
									label="Mantenha-me conectado"
									toggle
									onChange={changeKeepLogged}
								/>
								<Button.Group fluid size="medium">
									{!forgot && (
										<Button
											as="a"
											icon="idea"
											content="Esqueci"
											basic
											color="grey"
											onClick={handleForgotPassword}
										/>
									)}
									{!forgot && <Button.Or text="ou" />}
									<Button
										icon="sign in"
										content="Entrar"
										positive
										onClick={handleSubmit}
									/>
								</Button.Group>
							</Segment>
						</Form>
						<Message
							error={error?.name === 'NotAuthorizedException'}
							onDismiss={
								error?.name === 'NotAuthorizedException'
									? handleDismissError
									: undefined
							}
						>
							{error?.name === 'NotAuthorizedException' ? (
								'Usuário e senha inválidos.'
							) : (
								<>
									Não é cadastrado? <Link to="/signup">Inscreva-se</Link>
								</>
							)}
						</Message>
						{/* <GoogleButton handleGoogleLogin={handleGoogleLogin} /> */}
					</Grid.Column>
				</Grid>
			</ErrorBoundary>
			<VerifyCode Username={loginData.username} Password={loginData.password} />
		</Segment>
	);
};

export default LoginForm;

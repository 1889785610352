import React, { useEffect } from 'react';

import { useHistory, useParams } from 'react-router-dom';
import { Segment } from 'semantic-ui-react';

import NotFound from '../components/errors/notfound';
import Dots from '../loader/dots';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getRedirect } from '../store/redirects';

export const Direct = () => {
	const history = useHistory();
	const dispatch = useAppDispatch();

	const { org, redirect } = useParams<{ org: string; redirect: string }>();
	const { to, loading } = useAppSelector((state) => ({
		to: state.rdr.redirect,
		loading: state.rdr.loading,
	}));

	useEffect(() => {
		if (org && redirect) {
			dispatch(
				getRedirect({
					org,
					redirect,
				})
			);
		}
	}, [dispatch, org, redirect]);

	useEffect(() => {
		if (to) history.push(`/${to.path}/${to.item}`);
	}, [history, to]);

	if (loading)
		return (
			<Segment basic padded="very">
				<Dots title="Buscando..." />;
			</Segment>
		);

	return <NotFound />;
};

import React, { useEffect, useState } from 'react';

import { AttributeType } from '@aws-sdk/client-cognito-identity-provider';
import { DateCalendar } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { IMaskInput } from 'react-imask';
import {
	formatPhoneNumberIntl,
	parsePhoneNumber,
	isPossiblePhoneNumber,
	Value as PhoneNumberValue,
} from 'react-phone-number-input';
import {
	DropdownProps,
	Form,
	Icon,
	InputOnChangeData,
	Popup,
} from 'semantic-ui-react';

import { UserProps } from './user';
import { useLocale } from '../../hooks';
import { authUpdateUserAttributes } from '../../store/auth';
import { useAppDispatch } from '../../store/hooks';
import { formatedDate, week } from '../../util/dates';
import { countryOptions } from '../../util/places/countries';
import checkValidity from '../../util/valida/check';

interface ProfileProps {
	profile: UserProps;
	loading: boolean;
	setProfile: React.Dispatch<React.SetStateAction<UserProps>>;
}

enum Gender {
	MALE = 'male',
	FEMALE = 'female',
}

type GenderOptions = {
	value: Gender;
	icon: 'man' | 'woman';
	pt_BR: 'Masculino' | 'Feminino';
};

const Attributes: React.FC<ProfileProps> = ({
	profile,
	loading,
	setProfile,
}) => {
	const dispatch = useAppDispatch();
	const locale = useLocale();

	const handleProfile = (
		event: React.ChangeEvent<HTMLInputElement>,
		data: InputOnChangeData
	) => {
		setProfile((profile) => ({
			...profile,
			[data.field]: {
				value: data.value,
				changed: true,
				error: checkValidity(data.field, data.value),
			},
		}));
	};

	const countrySign = locale.split('_')?.[1].toLowerCase();

	const [country, setCountry] = useState<string>(countrySign);

	useEffect(() => {
		const parsedPhone = parsePhoneNumber(profile.phone_number?.value ?? '');

		if (parsedPhone?.country) setCountry(parsedPhone?.country.toLowerCase());
	}, [countrySign, profile.phone_number]);

	const findCountryBySign = (sign: string) =>
		countryOptions.find(
			(country) => country.value.toLowerCase() === sign?.toLowerCase()
		);

	const handleValidation = (field: string, value?: string | boolean) => {
		switch (field) {
			case 'phone_number':
				return (
					typeof value !== 'string' ||
					checkValidity(field, value) ||
					isPossiblePhoneNumber(value.replace('+', ''))
				);
			default:
				return typeof value !== 'string' || checkValidity(field, value);
		}
	};

	const handleCountry = (
		event: React.SyntheticEvent<HTMLElement, Event>,
		data: DropdownProps
	) => {
		const countryCode = findCountryBySign(`${data.value}`)?.code;

		setCountry(`${data.value}`);
		setProfile((prevProfile) => ({
			...prevProfile,
			phone_number: {
				value: `+${countryCode}` as PhoneNumberValue,
				changed: true,
				error: handleValidation('phone_number', `+${countryCode}`),
			},
		}));
	};

	const handlePhone = (
		event: React.ChangeEvent<HTMLInputElement>,
		data: InputOnChangeData
	) => {
		const parsedValue =
			parsePhoneNumber(data.value)?.number?.replace('+', '') ||
			data.value.replace('+', '');

		setProfile((prevProfile) => ({
			...prevProfile,
			phone_number: {
				value: `+${parsedValue}` as PhoneNumberValue,
				changed: true,
				error: handleValidation('phone_number', `+${parsedValue}`),
			},
		}));
	};

	const [openCalendar, setOpenCalendar] = useState(false);

	const handleCalendarChange = (date: Date) => {
		setProfile((state) => ({
			...state,
			birthdate: {
				value: dayjs(date).format('YYYY-MM-DD'),
				changed: true,
				error: handleValidation('birthdate', dayjs(date).format('YYYY-MM-DD')),
			},
		}));
	};

	const genderOptions: GenderOptions[] = [
		{
			value: Gender.MALE,
			icon: 'man',
			pt_BR: 'Masculino',
		},
		{
			value: Gender.FEMALE,
			icon: 'woman',
			pt_BR: 'Feminino',
		},
	];

	const handleGender = (
		event: React.SyntheticEvent<HTMLElement, Event>,
		data: DropdownProps
	) => {
		setProfile((profile) => ({
			...profile,
			[data.field]: {
				value: data.value,
				changed: true,
				error:
					typeof data.value === 'string' &&
					checkValidity(data.field, data.value),
			},
		}));
	};

	const configUserAttributes = (profile: UserProps) =>
		Object.keys(profile).reduce<AttributeType[]>(
			(prev, key) => [
				...prev,
				...(profile[key as keyof UserProps]?.value &&
				profile[key as keyof UserProps]?.changed &&
				!profile[key as keyof UserProps]?.error
					? [
							{
								Name: key,
								Value: profile[key as keyof UserProps]?.value,
							},
						]
					: []),
			],
			[]
		);

	const setUserAttributes = () => {
		const UserAttributes = configUserAttributes(profile);

		dispatch(authUpdateUserAttributes(UserAttributes));
	};

	return (
		<Form>
			<Form.Group>
				<Form.Input
					value={profile.name?.value}
					field="name"
					label="Nome"
					placeholder="Nome"
					required
					width={12}
					onChange={handleProfile}
				/>
				<Form.Input
					value={profile.nickname?.value}
					field="nickname"
					label="Apelido"
					placeholder="Apelido"
					width={4}
					onChange={handleProfile}
				/>
			</Form.Group>
			<Form.Group>
				<Popup
					on={['hover', 'focus']}
					trigger={
						<Form.Input
							value={profile.email?.value}
							field="email"
							label="E-mail"
							placeholder="E-mail"
							icon="warning"
							required
							width={16}
							onChange={handleProfile}
						/>
					}
					content="O e-mail é utilizado como identificador no sistema."
					position="top right"
				/>
			</Form.Group>
			<Form.Group>
				<Form.Dropdown
					value={country}
					field="country"
					label="Pais"
					basic
					fluid
					button
					width={3}
					options={countryOptions.map(
						(country: {
							code: string;
							value: string;
							en_US: string;
							pt_BR: string;
							continent: string;
						}) => ({
							key: country.value,
							value: country.value,
							flag: country.value,
							content: country.pt_BR,
						})
					)}
					onChange={handleCountry}
				/>
				<Form.Input
					value={
						formatPhoneNumberIntl(
							profile.phone_number?.value as PhoneNumberValue
						) || profile.phone_number?.value
					}
					field="phone_number"
					label="Celular"
					placeholder="Celular"
					fluid
					width={13}
					onChange={handlePhone}
				/>
			</Form.Group>
			<Form.Group>
				<Form.Input label="Nascimento" icon width={8}>
					<IMaskInput
						value={formatedDate(profile.birthdate?.value)}
						mask={Date}
						placeholder="Data de nascimento"
						onClick={() => setOpenCalendar(true)}
						onComplete={(value) => {
							handleCalendarChange(
								dayjs(value.split('.').reverse().join(', ')).toDate()
							);
						}}
					/>
					<Popup
						open={openCalendar}
						position="bottom right"
						trigger={<Icon name="calendar" />}
						style={{ padding: 0 }}
						onClose={() => setOpenCalendar(false)}
					>
						<DateCalendar
							value={dayjs(profile.birthdate?.value)}
							autoFocus
							dayOfWeekFormatter={(date) => week[date.day()]}
							views={['year', 'month', 'day']}
							onChange={(newValue, selectionState) => {
								handleCalendarChange(dayjs(newValue).toDate());

								if (selectionState === 'finish') setOpenCalendar(false);
							}}
						/>
					</Popup>
				</Form.Input>
				<Form.Select
					value={profile.gender?.value}
					field="gender"
					fluid
					label="Gênero"
					placeholder="Gênero"
					width={8}
					options={genderOptions.map((gender) => ({
						key: gender.value,
						value: gender.value,
						icon: gender.icon,
						text: gender.pt_BR,
					}))}
					onChange={handleGender}
				/>
			</Form.Group>

			<Form.Button
				type="submit"
				basic
				positive
				floated="right"
				icon="save"
				content="Salvar"
				loading={loading}
				style={{ marginTop: '1rem' }}
				onClick={setUserAttributes}
			/>
		</Form>
	);
};

export default Attributes;

import React, { useEffect } from 'react';

import { Container, Header, Image } from 'semantic-ui-react';

import Policy from './policy';
import { useTitle } from '../../../hooks/useTitle';

const Index: React.FC = () => {
	useTitle('Ponto do Esporte - Política de Privacidade');

	useEffect(() => {
		const element = document.getElementById('policy');
		const y = element?.getBoundingClientRect()?.top ?? 0 + window.scrollY;
		window.scroll({
			top: y,
			behavior: 'smooth',
		});
	}, []);

	return (
		<Container
			id="policy"
			style={{
				paddingTop: '40px',
				paddingBottom: '40px',
			}}
		>
			<Header
				style={{
					color: '#aace46',
					fontSize: '28px',
				}}
			>
				<Image src="img/pe/seta.gif" size="big" />
				Política de Privacidade
			</Header>
			<Policy />
		</Container>
	);
};

export default Index;

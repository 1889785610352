const normalizeField = (field: string, value: string) => {
	switch (field) {
		case 'mail' || 'email':
			return value
				.trim()
				.toLowerCase()
				.normalize('NFD')
				.replace(/[\u0300-\u036f]/g, '');
		default:
			return value.trim();
	}
};

export default normalizeField;

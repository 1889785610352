import { validaCNPJ } from './cnpj';
import { validaCPF } from './cpf';

const checkValidity = (
	field: string,
	value?: string | string[] | number | boolean
) => {
	switch (field) {
		case 'name':
			return (
				!value ||
				(typeof value === 'string' &&
					!/^\s*([A-ZÀ-Úa-zà-ú0-9"]{1,}([.,] |[-']| ))+[A-ZÀ-Úa-zà-ú0-9"]+\.?\s*$/gim.test(
						value
					))
			);
		case 'title':
			return !value;
		case 'number' || 'points':
			return (
				!value || (typeof value === 'string' && !/^[0-9]*$/gim.test(value))
			);
		case 'mail' || 'email':
			return (
				!value ||
				(typeof value === 'string' &&
					!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
						value
					))
			);
		case 'phone' || 'phone_number':
			return (
				!value ||
				(typeof value === 'string' &&
					(value.length === 0 ||
						value.replace(/\+|\(|\)|_|-| /g, '').length <= 10))
			);
		case 'password':
			return (
				!value ||
				(typeof value === 'string' &&
					!/(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/.test(
						value
					))
			);
		case 'birth' || 'birthdate' || 'entry' || 'begin':
			return (
				!value ||
				((typeof value === 'string' || typeof value === 'number') &&
					!(new Date(value) instanceof Date))
			);
		case 'gender':
			return (
				!value ||
				(typeof value === 'string' &&
					!['male', 'female', 'all'].includes(value))
			);
		case 'cnpj':
			return !(typeof value === 'string' && validaCNPJ(value));
		case 'cpf':
			return !(typeof value === 'string' && validaCPF(value));
		case 'policy':
			return !value;
		case 'category':
			return (
				!value ||
				(typeof value === 'string' &&
					!['club', 'acad', 'cond', 'pers'].includes(value))
			);
		case 'logo':
			return (
				!value ||
				(typeof value === 'string' &&
					value.length !== 0 &&
					!/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/.test(
						value
					))
			);
		case 'sports':
			return !value || (Array.isArray(value) && value.length === 0);
		case 'sign':
			return !value || (typeof value === 'string' && value.length === 0);
		case 'edition':
			return !value || (typeof value === 'string' && value.length === 0);
		case 'verification':
			return !value || (typeof value === 'string' && value.length === 0);
		case 'schedule':
			return !Array.isArray(value);
		default:
			return false;
	}
};

export default checkValidity;
